.image-overlay-container {
    display: flex;
    position: relative;
    justify-content: center;
    img {
        max-height: 330px;
    }
    .is-overlay{
        background-color: transparent;
        &:hover{
            background-color: black;
            opacity: 0.4;
        }
    }
}